<template>
  <layout>
    <template #secondarySidebar>
      <settings-nav />
    </template>

    <alert-warning v-if="! state.hasAccess" title="Access denied">
      You are not allowed to add Disabled Owner
    </alert-warning>

    <div v-else>
      <div class="flex flex-row justify-between items-center border-b pb-2">
        <h2 class="font-bold text-lg sm:text-2xl">Add Disabled Owner</h2>
        <router-link :to="backRoute" class="group">
          <div class=" flex items-center">
            <svg class="w-4 h-4 text-gray-400 group-hover:text-gray-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
            <p class="text-sm text-gray-500 group-hover:text-gray-900">Back</p>
          </div>
        </router-link>
      </div>

      <div class="mt-6 xl:mt-8">
        <form-layout
          @submit="create"
          title="Disabled Owner"
          icon="no_accounts"
          :is-loading="state.isInitialLoading"
        >
          <div class="space-y-4">
            <errors :errors="state.errors" />
            <autocomplete-text-input
              type="email"
              label="Email"
              v-model="state.form.email"
              :required="true"
              :suggestions="state.users"
            />
          </div>
          <template #actions>
            <submit-button :is-loading="state.isSaving">Create</submit-button>
          </template>
        </form-layout>
      </div>
    </div>
  </layout>
</template>

<script>
import { reactive, watch } from 'vue'
import http from '@/services/http.js'
import notification from '@/services/notifications.js'
import { store, getStoreProfile } from '@/services/store.js'
import Layout from '@/layouts/Default.vue'
import FormLayout from '@/components/form/Layout.vue'
import Errors from '@/components/form/Errors.vue'
import AutocompleteTextInput from '@/components/form/AutocompleteText.vue'
import AlertWarning from '@/components/alerts/Warning.vue'
import { useRouter } from 'vue-router'
import SubmitButton from '@/components/form/SubmitButton.vue'
import SettingsNav from '@/components/settings/Nav'

export default {
  name: 'settings-disabled-owners-create',

  components: {
    Layout,
    AlertWarning,
    FormLayout,
    AutocompleteTextInput,
    SubmitButton,
    Errors,
    SettingsNav
  },

  setup() {
    const router = useRouter()
    const backRoute = { name: 'SettingsDisabledOwnersIndex' }
    const state = reactive({
      hasAccess: true,
      errors: [],
      isInitialLoading: true,
      isSaving: false,
      form: {},
      users: [],
    })

    const init = () => {
      if (store.profileLoaded) {
        state.isInitialLoading = false
        if (!store.profileData.is_administrator) {
          state.hasAccess = false
        }
        else {
          getUsers()
        }
      }
    }

    const create = async () => {
      state.errors = []
      state.isSaving = true

      try {
        await http.post(`/disabled-owners/${state.form.email}`)

        router.push(backRoute)
      } catch (e) {
        if (e.response) {
          state.errors.push(e.response.data.Message)
        } else {
          state.errors.push('Something went wrong. Please, try again.')
        }
      }

      state.isSaving = false
    }

    const getUsers = async () => {
      try {
        const { data } = await http.get('disabled-owners')
        data.all.sort((a, b) => a.email.localeCompare(b.email))
        state.users = data.all.map(user => user.email)
      } catch (e) {
        notification.error('Error loading Users for Owner Autocomplete')
      }
    }

    getStoreProfile()
    
    init()
    watch(
      () => store.profileData.is_administrator,
      () => {
        init()
      }
    )

    return {
      state,
      create,
      backRoute,
    }
  }
}
</script>
