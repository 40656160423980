<template>
  <div>
    <label :for="name" :class="{ 'sr-only': !label, 'block text-sm font-semibold text-blumine mb-1': label }">
      {{ label }} <span v-if="required" class="text-shakespear">*</span>
    </label>
    <div class="border-big-stone border-opacity-25">
      <input
        :id="name"
        :type="type"
        :required="required"
        :readonly="isLoading || readonly"
        :disabled="disabled"
        :value="modelValue"
        :autocomplete="false"
        :placeholder="placeholder"
        :maxlength="maxlength"
        :class="{ 'bg-gray-50': readonly || disabled, 'pl-6': search, ' mt-1': label }"
        class="block w-full border-big-stone border-opacity-20 rounded-md text-blumine placeholder-gray-500 focus:ring-sail focus:border-sail focus:outline-none focus:shadow-outline-sail transition duration-150 ease-in-out"

        @keydown.enter="enter"
        @keydown.down="down"
        @keydown.up="up"
        @input="change"
      >
    </div>

    <div v-if="state.open" class="relative">
      <ul class="absolute w-full z-50">
        <li v-for="(suggestion, index) in matches"
          v-bind:key="index"
          v-bind:class="{'bg-gray-200': isActive(index)}"
          @click="suggestionClick(index)"
          class="border-solid border border-light-blue-500
          p-2 cursor-pointer
          bg-white hover:bg-gray-300"
        >
          {{ suggestion }}
        </li>
      </ul>
    </div>

    <error v-if="error">{{ error }}</error>
  </div>
</template>

<script>
import { computed, reactive } from 'vue'
import Error from '@/components/form/Error.vue'

export default {
components: {
    Error
  },

  props: {
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String
    },
    modelValue: {},
    error: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    required: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Boolean,
      default: false,
    },
    maxlength: {},

    suggestions: {
        type: Array,
        required: true
    },
  },

  setup(props, { emit }) {
    const state = reactive({
      open: false,
      current: 0
    })

    const matches = computed(() => {
      if (props.modelValue.length === 0) {
        return
      }
      return props.suggestions.filter((suggestion) => {
        return suggestion.toLowerCase().includes(props.modelValue.toLowerCase())
      })
    })

    const updateValue = (value) => {
      emit('update:modelValue', value)
    }

    const enter = (e) => {
      e.preventDefault()
      updateValue(matches.value[state.current])
      state.open = false
    }

    const up = (e) => {
      e.preventDefault()
      if (state.current > 0) {
        state.current--
      }
    }

    const down = (e) => {
      e.preventDefault()
      if (state.current < matches.value.length-1) {
        state.current++
      }
    }

    const isActive = (index) => {
      return index === state.current
    }

    const change = (e) => {
      updateValue(e.target.value)
      if (state.open === false) {
        state.open = true
        state.current = 0
      }
    }

    const suggestionClick = (index) => {
      updateValue(matches.value[index])
      state.open = false
    }

    return {
      props,
      state,
      matches,
      enter,
      up,
      down,
      isActive,
      change,
      suggestionClick,
    }
  }
}

</script>